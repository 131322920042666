import { useContext, useEffect, useState } from 'react'
import classnames from 'classnames'
import Template from "./Template"
import Title from "./Title"
import ProfileList from './SortLists/ProfileList'
import QueueList from './SortLists/QueueList'
import { ApiClient } from '../etc'
import { useProfiles } from '../utils/hooks'
import { ProfileContext } from '../utils/context/profileContext'


const SortProfiles = ({ setPage, setPageState }) => {

	const api = new ApiClient();

	const [state, setState] = useState({
		tab: 'profiles',
		profileOrder: {},
		queueIndex: 1,
		queue: []
	})

	const { tab, profileOrder, queueIndex, queue } = state;

	const [profileState] = useContext(ProfileContext)
	const { loadAllProfiles } = useProfiles()

	useEffect(() => {

		loadAllProfiles()
			.then(profiles => {
				var profileOrder = {}
				for(let profile of profiles) {
					profileOrder[profile.id] = '-';
				}

				setState({
					...state,
					profileOrder: profileOrder
				})
			})
			.catch(err => console.log(err))

	}, [])

	const renderTabs = () => {
		const profileList = profileState.all_profiles; 

		if (profileList) {
			if (tab === "profiles") {
				return <ProfileList list={profileList} queueUp={queueUp} profileOrder={profileOrder} remove={remove} setPageState={setPageState} />
			} else if (tab === "queue") {
				return <QueueList list={queue} queueUp={queueUp} profileOrder={profileOrder} handleRemove={handleRemove} remove={remove} />
			}
		}
	}

	const remove = async id => {
		const res = window.confirm("Are you sure you want to delete this artist profile?");

		if (res) {
			const newList = {...state.profileOrder};

			if (newList[id] === '-') {
				delete newList[id];
				await api.removeProfile(id);
				
				setState({
					...state,
					profileOrder: newList
				})
			} else {
				const place = newList[id];
				const newQueue = [...state.queue];
				newQueue.splice(place, 1);

				for (const key in newList) {
					if (profileOrder[key] > place + 1) {
						newList[key] = newList[key] - 1;
					} 
				}

				delete newList[id];
				await api.removeProfile(id);

				setState({
					...state,
					profileOrder: newList,
					queue: newQueue,
					queueIndex: state.queueIndex > 1 ? state.queueIndex - 1 : state.queueIndex,
				})
			}
		}
	}

	const queueUp = id => {
		if(profileOrder[id] === '-') {

			var po = {...profileOrder};
			po[id] = queueIndex;

			let item = profileState.all_profiles.filter(profile => {
				return profile.id === id;
			})

			let queueArr = [...queue];
			queueArr.push(item[0]);

						setState({
				...state,
				profileOrder: po,
				queueIndex: queueIndex + 1,
				queue: queueArr,
			})
		}
	}

	const handleClear = () => {
		const temp = {...profileOrder};
		for (let profile in temp) {
			temp[profile] = '-';
		}

		setState({
			...state,
			profileOrder: temp,
			queueIndex: 1,
			queue: []
		})
	}

	const handleBack = () => {
		handleClear();
		setPage('home');
	}

	const handleRemove = (i) => {
		const newList = [...state.queue];
		newList.splice(i, 1);

		const profileOrder = {...state.profileOrder};

		for (const key in profileOrder) {
			if (profileOrder[key] === i + 1) {
				profileOrder[key] = "-";
			}
			if (profileOrder[key] > i + 1) {
				profileOrder[key] = profileOrder[key] - 1;
			} 
		}

		setState({
			...state,
			queueIndex: state.queueIndex > 1 ? state.queueIndex - 1 : state.queueIndex,
			queue: newList,
			profileOrder: profileOrder
		})
	}

	const handleSave = () => {
		const newProfileOrder = {...state.profileOrder};

		for (const key in newProfileOrder) {
			if (newProfileOrder[key] === "-") {
				newProfileOrder[key] = 9999;
			}
		}

		api.submitOrder(newProfileOrder);
	}

	return (
		<Template>
			<Title dashboard />
			<div className='leaderboard'>
				<div className='leaderboard-header'>
					<div className={classnames('leaderboard-header-category', { 'highlight-text': tab === 'profiles'})} onClick={() => setState({...state, tab: 'profiles'})}>
						<h1>Profiles</h1>
					</div>
					<div className={classnames('leaderboard-header-category', { 'highlight-text': tab === 'queue'})} onClick={() => setState({...state, tab: 'queue'})}>
						<h1>Queue</h1>
					</div>
				</div>
				{ renderTabs() }
				<div className='leaderboard-panel'>
					<button onClick={() => handleBack()}>Back</button>
					<button onClick={() => handleSave()}>Save</button>
					<button onClick={() => handleClear()}>Clear</button>
				</div>
			</div>
		</Template>
	)
}

export default SortProfiles