import { useContext } from 'react'
import { MusicContext } from '../context/musicContext'

const useMusic = () => {

    const [musicState, setMusicState] = useContext(MusicContext)

    const toggle = () => {
        setMusicState({ ...musicState, isPlaying: !musicState.isPlaying })
    }

    const changeVolume = (value) => {
        setMusicState({
            ...musicState,
            volume: value
        })
    }

    return {
        toggle,
        changeVolume,
        volume: musicState.volume
    }

}

export default useMusic