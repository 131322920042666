import { useEffect, Fragment, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom';
import Template from '../components/Template'
import Title from '../components/Title'
import Image from '../components/Image'
import Info from '../components/Info'
import Button from '../components/Button'
import ButtonGroup from '../components/ButtonGroup'
import { ProfileContext } from '../utils/context/profileContext'
import { useProfiles, useToken } from '../utils/hooks'
import { ApiClient } from '../etc'
import InstructionText from './InstructionText'
import Music from './Music'
import { AuthContext } from '../utils/context/authContext';

const promptLimit = 7

const Game = () => {

    const [controls, setControls] = useState(0)
    const [table, setTable] = useState(null)
    const [count, setCount] = useState(0)
    const [shouldDisplayPrompt, setShouldDisplayPrompt] = useState(false)
    const [styles, setStyles] = useState({
        bgColor: '#000',
        musicBtnColor: '#FFFF00',
        templateBorder: '1px solid rgba(255, 255, 255, 0.453)',
        titleColor: '#000'
    })
    const [promptCount, setPromptCount] = useState(0)
    const { getToken } = useToken()
    const [profileState] = useContext(ProfileContext)
    const [authState] = useContext(AuthContext)
    const { nextProfile, loadProfiles } = useProfiles()
    let history = useHistory()

    useEffect(() => {

        const query = new URLSearchParams(history.location.search)
        const load = query.get('load') || 'user'
        loadProfiles(load)

    }, [])
    
    const vote = async vote => {

        try {

            const profile_id = profileState.profiles[0].id
            const api = new ApiClient()
            const token = getToken()
            await api.vote(token, profile_id, vote)
            const stats = await api.profileStats(profile_id)

            setCount(count + 1)
            setControls(1)
            setTable({
                type: vote,
                star: stats.star,
                leader: stats.leader,
                noone: stats.noone
            })

        } catch (err) {
            console.log(err)
            throw new Error(err)
        }

    }

    const yes = () => {
        history.push('/login')
    }

    const next = () => {
        setControls(0)
        setTable(null)

        if(promptCount === promptLimit) {
            setPromptCount(0)
            setShouldDisplayPrompt(true)
            setStyles({
                ...styles,
                bgColor: '#FFFF00',
                musicBtnColor: '#000',
                templateBorder: '1px solid #000',
                titleColor: '#000'
            })
        } else if(authState.isAnon) {
            setPromptCount(promptCount + 1)
            setShouldDisplayPrompt(false)
            setStyles({
                ...styles,
                templateBorder: '1px solid rgba(255, 255, 255, 0.453)',
                bgColor: '#000',
                musicBtnColor: '#FFFF00'
            })
        }

        if (profileState.profiles.length === 1) {
            history.push('/leaderboard')
        } else {
            nextProfile()
        }
    }

    const share = async () => {
        const api = new ApiClient();
        const loggedIn = await api.isLoggedInTwitter();

        if (loggedIn === true) {
            const text = `Can't believe ${table.noone}%25 of people think ${profileState.profiles[0].name} is no one starvsleader.com`;
            window.open(`https://twitter.com/intent/tweet?text=${text}`, '_blank');
        } else {
            history.push('/login');
        }
    }

    const renderControls = () => {
        if(controls === 0) {
            return (
                <Fragment>
                    <Button className='background-tint' click={vote} input='star'>STAR</Button>
                    <Button className='background-tint text-muted' click={vote} input='noone'>NO ONE</Button>
                    <Button className='background-tint' click={vote} input='leader'>LEADER</Button>
                </Fragment>
            )
        } else if (controls === 1) {
            return (
                <Fragment>
                    <Button click={share} className='desktop-height-2-3'>SHARE</Button>
                    <Button click={next} className='desktop-height-1-3'>NEXT</Button>
                </Fragment>
            )
        }
    }

    const renderBody = () => {
        if(count >= 6) {
            setTable(null)
            setCount(0)
            setControls(2)
            return (
                <Fragment>
                    <Title />
                    <ButtonGroup>
                        { renderControls() }
                    </ButtonGroup>
                    <Info
                        name='Curious to see where everyone ranks?'
                        table={null}
                    />
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <Title />
                    <ButtonGroup>
                        { renderControls() }
                    </ButtonGroup>
                    <Info
                        name={ profileState.profiles.length > 0 ? profileState.profiles[0].name : '' }
                        blurb={ profileState.profiles.length > 0 ? profileState.profiles[0].blurb : '' }
                        table={table}
                    />
                </Fragment>
            )
        }
    }

    return (
        <>
            <Template bgColor={styles.bgColor} style={{ border: styles.templateBorder }}>
                <Music color={styles.musicBtnColor} displayLink={!shouldDisplayPrompt} />
                {
                    shouldDisplayPrompt ? (
                        <>
                            <Title textColor={styles.titleColor} />
                            <InstructionText
                                body_text="Curious to see where everyone ranks"
                                footer_text="Log in"
                            />
                            <ButtonGroup borderColor='#000' className='yellow-buttons'>
                                <Button click={yes} textColor='#000'>
                                    Log In
                                </Button>
                                <Button click={next} textColor='#000'>
                                    Maybe Later
                                </Button>
                            </ButtonGroup>
                        </>
                    ) : renderBody()
                }
            </Template>
            {
                !shouldDisplayPrompt &&
                <Image img={ profileState.profiles.length > 0 ? profileState.profiles[0].image : '' } />
            }
        </>
    )

}

export default Game