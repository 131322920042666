import React, { useContext } from "react"
import "./ListItem.scss"
import { ConfigContext } from '../../utils/context/configContext'
import remove from "../../svg/Remove.svg"
import trash from "../../svg/Trash.svg"
import { Edit } from '../../svg'

const ListItem = ({
  queueUp,
  profileOrder,
  p,
  queueDisabled,
  handleRemove,
  idx,
  removeProfile,
  setPageState
}) => {

  const [config] = useContext(ConfigContext)

  const editProfile = () => {
    setPageState({
      page: 'edit',
      editProfileState: p
    })
  }

  const handleQueueUp = () => {
    if (!queueDisabled) {
      queueUp(p.id);
    }
  };

  let profIndex = profileOrder[p.id];

  if (queueDisabled) {
    profIndex = "";
  }

  let customStyle = {
    pointerEvents: "none",
    cursor: "default",
  };

  let removeElement = (
    <div
      className="remove-element"
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <button
        onClick={() => {
          handleRemove(idx);
        }}
      >
        <img src={remove} style={{ width: "25px" }} />
      </button>
    </div>
  );
  let indexElement = (
    <p className="leaderboard-list-item-left-index">{profIndex}</p>
  );

  return (
    <div
      className="leaderboard-list-item"
      style={queueDisabled ? customStyle : {}}
    >
      <div className="leaderboard-list-item-left" onClick={() => handleQueueUp()}>
        {queueDisabled ? removeElement : indexElement}
        <p className="leaderboard-list-item-left-name">{p.name}</p>
      </div>
      <div className="leaderboard-list-item-right" onClick={() => handleQueueUp()}>
        <img src={`${config.base_url}/images/${p.image}`} alt="profile" />
        <div className="trash">
          <button onClick={() => removeProfile(p.id)}><img src={trash} alt="trash" /></button>
        </div>
        <Edit onClick={editProfile} />
      </div>
    </div>
  );
};

export default ListItem;
