import { Game } from '../components'

const Home = () => {

    return (
        <div className='wrapper'>
            <Game />
        </div>
    )
}

export default Home