import React, { useState } from 'react';

const ConfigContext = React.createContext([{}, () => {}]);

const ConfigProvider = props => {
    const [state, setState] = useState({
        base_url: 'https://api.starvsleadertestwebsite.link'
    });

    return (
        <ConfigContext.Provider value={[state, setState]}>
            {props.children}
        </ConfigContext.Provider>
    );
};

export { ConfigProvider, ConfigContext };