import { AuthProvider } from './authContext'
import { ProfileProvider } from './profileContext'
import { MusicProvider } from './musicContext'
import { ConfigProvider } from './configContext'

const RootProvider = ({ children }) => {
    return (
        <AuthProvider>
            <ProfileProvider>
                <MusicProvider>
                    <ConfigProvider>
                        { children }
                    </ConfigProvider>
                </MusicProvider>
            </ProfileProvider>
        </AuthProvider>
    )
}

export default RootProvider