import axios from 'axios'

export default class ApiClient {

    constructor() {
        this.urls = {
            base: 'http://localhost:5052',
            base: 'https://api.starvsleadertestwebsite.link',
            auth: {
                login: '/login'
            },
            profiles: {
                base: '/profile',
                all: '/all',
                stats: '/profile/stats',
                totals: '/profile/totals',
                votedWith: '/profile/voted-with',
                profiles: '/profiles',
                create: '/create',
                delete: '/delete',
                save: '/save'
            },
            voting: {
                base: '/vote',
                completed: '/completed'
            },
            config: {
                base: '/config',
                order: '/order',
            },
            user: {
                base: '/user',
                merge: '/merge',
                myVotes: '/profiles/my-votes'
            },
            twitter: {
                base: '/twitter',
                credentials: '/credentials',
                auth: '/auth',
                loggedIn: '/is-logged-in'
            }
        }
    }

    login = async token => {

        try {
            
            const url = this.urls.base + this.urls.auth.login
            const { data } = await axios.post(url, { token })
            return data

        } catch (err) {
            throw new Error(err)
        }

    }

    loginWithCreds = async (email, password) => {
        try {
            
            const url = this.urls.base + this.urls.auth.login
            const { data } = await axios.post(url, {email, password})
            return data

        } catch (error) {
            throw new Error(error);
        }
    }

    profiles = async (token, load) => {

        try {

            const url = this.urls.base + this.urls.profiles.profiles
            const { data } = await axios.get(url, { params: { load }, headers: { 'x-auth-token': token }})
            return data

        } catch (err) {
            throw new Error(err)
        }

    }

    all_profiles = async () => {

        try {

            const url = this.urls.base + this.urls.profiles.base + this.urls.profiles.all
            const { data } = await axios.get(url)
            return data

        } catch (err) {
            throw new Error(err)
        }

    }

    vote = async (token, profile_id, vote) => {

        try {

            const url = this.urls.base + this.urls.voting.base
            const { data } = await axios.post(url, { profile_id, vote }, { headers: { 'x-auth-token': token }})
            return data

        } catch (err) {
            throw new Error(err)
        }

    }

    profileStats = async profile_id => {

        try {

            const url = this.urls.base + this.urls.profiles.stats
            const { data } = await axios.get(url, { params: { profile_id } })
            return data

        } catch (err) {
            throw new Error(err)
        }

    }

    profileTotals = async () => {

        try {

            const url = this.urls.base + this.urls.profiles.totals
            const { data } = await axios.get(url)
            return data

        } catch (err) {
            throw new Error(err)
        }

    }

    votedWith = async (token, user_id) => {

        try {
            
            const url = this.urls.base + this.urls.profiles.votedWith
            const { data } = await axios.get(url, { params: { user_id }, headers: { 'x-auth-token': token } })
            return data

        } catch (err) {
            throw new Error(err)
        }

    }

    submitOrder = async orderObject => {
        try {
            
            const url = this.urls.base + this.urls.config.base + this.urls.config.order
            const token = window.localStorage.getItem('token')

            const config = {
                headers: {
                    'x-auth-token': token
                }
            }

            await axios.post(url, orderObject, config)

        } catch (error) {
            throw new Error(error)
        }
    }

    uploadImage = async (name, blurb, image) => {
        try {

            const formData = new FormData()

            formData.set('image', image)
            formData.set('blurb', blurb)
            formData.set('name', name)
            
            const url = this.urls.base + this.urls.profiles.base + this.urls.profiles.create;
            const token = window.localStorage.getItem('token');

            const config = {
                headers: {
                    'x-auth-token': token,
                    'content-type': 'multipart/form-data'
                }
            }

            const { data } = await axios.post(url, formData, config);
            return true

        } catch (error) {
            return false
        }
    }

    saveProfile = async (id, name, blurb) => {

        try {

            const url = this.urls.base + this.urls.profiles.base + this.urls.profiles.save
            const { data } = await axios.post(url, { id, name, blurb })
            return true

        } catch (err) {
            console.log(err)
            return false
        }

    }

    mergeUser = async (token, email, password, password1) => {
        try {
            
            const url = this.urls.base + this.urls.user.base + this.urls.user.merge

            const config = {
                headers: {
                    'x-auth-token': token
                }
            }

            const merge = await axios.post(url, {email: email, password: password, password1: password1}, config)
            return merge

        } catch (error) {
            console.error(error);
            return false;
        }
    }

    getTwitterCreds = async () => {
        try {
            
            const url = this.urls.base + this.urls.twitter.base + this.urls.twitter.credentials;
            const data = await axios.get(url);

            return data.data;
        } catch (error) {
            console.error(error)
        }
    }

    removeProfile = async id => {
        try {
            
            const url = this.urls.base + this.urls.profiles.base + this.urls.profiles.delete;
            const token = window.localStorage.getItem('token');

            const config = {
                headers: {
                    'x-auth-token': token
                }
            }

            const deleteProfile = await axios.post(url, {profile_id: id}, config); 

            return deleteProfile;
        } catch (error) {
            throw new Error(error)
        }
    }

    getTwitterURL = async () => {
        try {
            
            const url = this.urls.base + this.urls.twitter.base + this.urls.twitter.auth;
            const token = window.localStorage.getItem('token');

            const config = {
                headers: {
                    'x-auth-token': token
                }
            }

            const authURL = await axios.get(url, config);

            return authURL.data;

        } catch (error) {
            throw new Error(error)
        }
    }

    isLoggedInTwitter = async () => {
        try {
            
            const url = this.urls.base + this.urls.twitter.base + this.urls.twitter.loggedIn;
            const token = window.localStorage.getItem('token');

            const config = {
                headers: {
                    'x-auth-token': token
                }
            }

            const loggedIn = await axios.get(url, config);

            return loggedIn.data;
        } catch (error) {
            throw new Error(error);
        }
    }

    myVotes = async token => {

        try {

            const url = this.urls.base + this.urls.user.myVotes
            const { data } = await axios.get(url, { headers: { 'x-auth-token': token }})
            return data

        } catch (err) {
            throw new Error(err)
        }

    }

    isVotingCompleted = async token => {

        try {

            const url = this.urls.base + this.urls.voting.base + this.urls.voting.completed
            const { data } = await axios.get(url, { headers: { 'x-auth-token': token }})
            return data

        } catch (err) {
            throw new Error(err)
        }

    }

}