import { useEffect, useState, Fragment } from 'react'
import classnames from 'classnames'
import Template from "../components/Template"
import Title from "../components/Title"
import ButtonGroup from '../components/ButtonGroup'
import Button from '../components/Button'
import SortProfiles from '../components/SortProfiles'
import { ApiClient } from '../etc'
import { useAuth } from '../utils/hooks'
import { useHistory } from 'react-router-dom'
import { EditProfile } from '../components/EditProfile'

const Dashboard = () => {

    const { user } = useAuth()
    const history = useHistory()

    useEffect(() => {

        if(user === undefined) {
            history.push('/')
        } else if('is_admin' in user && !user.is_admin) {
            history.push('/')
        }
        
    }, [])

    const [pageState, setPageState] = useState({
        page: 'home',
        editProfileState: {}
    })

    const link = page => setPageState({ ...pageState, page })

    const [state, setState] = useState({
        image: null,
        artistName: null,
        successful: null,
        blurb: ''
    })

    const api = new ApiClient();

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async () => {

        const image = document.getElementById('img-upload').files

        if (state.artistName && state.artistName.length > 0 && image.length > 0 && state.blurb && state.blurb.length > 0) {

            const upload = await api.uploadImage(state.artistName, state.blurb, image[0]);

            setState({
                ...state,
                successful: upload
            })
            
        } else {
            setState({
                ...state,
                successful: false
            })
        }
    }

    const feedback = classnames({
        "failed": state.successful === false,
        "success": state.successful
    })

    const render = () => {
        switch(pageState.page) {
            case 'home':
                return (
                    <Fragment>
                        <Template>
                            <Title dashboard />
                            <ButtonGroup>
                                <Button click={() => setPageState({ ...pageState, page: 'sort' })}>Sort Profiles</Button>
                                <div className="upload">
                                    <p className={feedback}>{state.successful ? "Success" : "Upload Failed"}</p>
                                    <div>
                                        <span>Upload a new artist</span>
                                        <input placeholder="Enter Artist's Name" type="text" name="artistName" onChange={(e) => handleChange(e)}/>
                                        <textarea
                                            name='blurb'
                                            onChange={(e) => handleChange(e)}
                                            value={state.blurb}
                                            placeholder='Profile Description...'
                                        ></textarea>
                                    </div>
                                    <input
                                        type="file"
                                        id="img-upload"
                                        name="image"
                                        accept="image/*"
                                        onChange={(e) => handleChange(e)}
                                        className='file-upload'
                                    />
                                    <button className='profile-upload-submit' onClick={() => handleSubmit()}>Submit</button>
                                </div>
                            </ButtonGroup>
                        </Template>
                    </Fragment>
                )
            case 'sort':
                return (
                    <SortProfiles setPage={link} setPageState={setPageState} />
                )
            case 'edit':
                return (
                    <EditProfile setPage={link} profile={pageState.editProfileState} />
                )
            default:
                return null
        }
    }

    return render()
}

export default Dashboard