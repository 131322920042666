import { useContext } from 'react'
import { ProfileContext } from '../context/profileContext'
import { ApiClient } from '../../etc'
import { useHistory } from 'react-router-dom'
import useToken from './useToken'

const useProfiles = () => {

    const [profileState, setProfileState] = useContext(ProfileContext)
    const history = useHistory()
    const { getToken } = useToken()
    const api = new ApiClient()

    const loadAllProfiles = async () => {

        try {

            const all_profiles = await api.all_profiles()
            setProfileState({ ...profileState, all_profiles })
            return all_profiles

        } catch (err) {
            throw new Error(err)
        }

    }

    const loadProfiles = async load => {

        try {

            const token = getToken()
            if(token) {
                const profiles = await api.profiles(token, load)
                if(profiles.length === 0) {
                    history.push('/leaderboard')
                } else {
                    setProfileState({ ...profileState, profiles })
                    return profiles
                }
            }

        } catch (err) {
            throw new Error(err)
        }

    }

    const nextProfile = () => {
        const profiles = [...profileState.profiles] // copy
        profiles.shift() // remove first item
        setProfileState({ ...profileState, profiles })
    }

    const loadLeaderboard = async token => {

        try {

            const [totals, myVotes] = await Promise.all([ api.profileTotals(), api.myVotes(token) ])
            setProfileState({ ...profileState, totals, myVotes })

        } catch (err) {
            throw new Error(err)
        }

    }

    const loadProfileTotals = async () => {

        try {
            const totals = await api.profileTotals()
            setProfileState({ ...profileState, totals })
        } catch (err) {
            console.log(err)
        }

    }

    const loadVotedWith = async () => {
        try {
            const token = getToken()
            const votedWith = await api.votedWith(token)
            setProfileState({ ...profileState, votedWith })
        } catch (err) {
            throw new Error(err)
        }
    }

    return {
        loadProfiles,
        nextProfile,
        loadProfileTotals,
        loadVotedWith,
        loadLeaderboard,
        loadAllProfiles,
        all_profiles: profileState.all_profiles
    }

}

export default useProfiles