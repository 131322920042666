import { useContext } from 'react'
import classnames from 'classnames'
import { useHistory } from 'react-router-dom'
import { Play, Pause } from '../svg'
import { MusicContext } from '../utils/context/musicContext'
import { useMusic } from '../utils/hooks'
import useAuth from '../utils/hooks/useAuth'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

const Music = ({ color, displayLink }) => {

    const [musicState] = useContext(MusicContext)
    const { toggle, changeVolume } = useMusic()
    const { isAnon, user } = useAuth()
    let history = useHistory()

    let signInClass = classnames({
        "active": history.location.pathname === "/",
        "sign-in-button": true
    })

    const handleMusicChange = (value) => {
        changeVolume(value);
    }

    const login = displayLink ? <a href="/login">Sign In</a> : <></>
    const leaderboard = displayLink ? <a href="/leaderboard">Leaderboard</a> : <></>

    return (
        <div className='music'>
            <div>
                <div className="controls">
                    <Slider value={musicState.volume} orientation="horizontal" min={0} max={.9999} step={.01} tooltip={false} onChange={handleMusicChange} />
                    {
                        musicState.isPlaying ?
                        <Pause color={color} onClick={toggle} /> :
                        <Play color={color} onClick={toggle} />
                    }
                </div>
                <div className={signInClass}>
                    { isAnon === true || isAnon === undefined ? login : leaderboard }
                </div>
            </div>
        </div>
    )
}

export default Music