import { useHistory } from 'react-router-dom'

const useToken = () => {

    const history = useHistory()

    const getToken = () => {

        const token = window.localStorage.getItem('token')
        if(!token) history.push('/')

        return token

    }

    return {
        getToken
    }

}

export default useToken