import { Fragment, useContext, useEffect } from 'react'
import './App.scss'
import { Home, Leaderboard } from './pages'
import { AuthContext } from './utils/context/authContext'
import { MusicContext } from './utils/context/musicContext'
import useAuth from './utils/hooks/useAuth'
import Instructions from './components/Instructions'
import ReactPlayer from 'react-player'
import { Switch, Route } from 'react-router-dom'
import Dashboard from './pages/Dashboard'
import Login from './pages/Login'
import { ConfigContext } from './utils/context/configContext'

const App = () => {

	const [config] = useContext(ConfigContext)
	const [authState] = useContext(AuthContext)
	const [musicState] = useContext(MusicContext)
	const { loadUser } = useAuth()
	const audio_url = `${config.base_url}/audio/song.mp4`

	useEffect(() => {

		loadUser()

	}, [])

	const render = () => {
		if(authState.isLoggedIn) {
			return (
				<Fragment>
					<ReactPlayer
						url={audio_url}
						className='audio-player'
						playing={musicState.isPlaying}
						volume={musicState.volume}
						loop
						playsinline
					/>
					<Switch>
						<Route exact path='/'>
							<Home />
						</Route>
						<Route exact path='/leaderboard'>
							<Leaderboard />
						</Route>
						<Route exact path='/instructions'>
							<Instructions />
						</Route>
						<Route exact path='/the-dopest-dashboard'>
							<Dashboard />
						</Route>
						<Route exact path='/login'>
							<Login />
						</Route>
					</Switch>
				</Fragment>
			)
		} else {
			return (
				null
			)
		}
	}

	return render()

}

export default App;
