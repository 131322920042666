import React from 'react';
import ListItem from './ListItem';


const QueueList = ({list, profileOrder, queueUp, handleRemove, removeProfile}) => {

	return (
		<div className="leaderboard-list">
			{list.map((p, idx) => {
				return <ListItem queueUp={queueUp} profileOrder={profileOrder} p={p} key={idx} queueDisabled handleRemove={handleRemove} idx={idx} removeProfile={removeProfile} />;
			})}
		</div>
	);
}

export default QueueList;