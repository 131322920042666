import { useState, useContext } from 'react'
import './_EditProfile.scss'
import { ConfigContext } from '../../utils/context/configContext'
import { ApiClient } from '../../etc'

const EditProfile = ({ profile, setPage }) => {
    
    const [config] = useContext(ConfigContext)
    const api = new ApiClient()

    const [state, setState] = useState({
        name: profile.name,
        blurb: profile.blurb,
        success: false,
        error: false
    })

    const success = <div className='success-alert'>Success!</div>
    const error = <div className='error-alert'>Error!</div>

    const _handleChange = e => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const submit = async () => {
        
        try {

            const { name, blurb } = state
            const { id } = profile
            const bool = await api.saveProfile(id, name, blurb)
            setState({ ...state, success: true, error: false })
            setTimeout(() => setState({ ...state, success: false }), 3000)

        } catch (err) {
            setState({ ...state, success: false, error: true })
            setTimeout(() => setState({ ...state, error: false }), 3000)
        }

    }

    return (
        <div className='edit-profile'>
            <div className='back-btn'>
                <p onClick={() => setPage('sort')}>Back</p>
            </div>
            <div className='edit-profile-body'>
                <div className='edit-profile-body-title'>
                    <input
                        type='text'
                        name='name'
                        value={state.name}
                        onChange={_handleChange}
                    />
                    <img src={`${config.base_url}/images/${profile.image}`} alt='profile' />
                </div>
                <textarea
                    value={state.blurb}
                    onChange={_handleChange}
                    placeholder='Profile Description...'
                    name='blurb'
                ></textarea>
                <button onClick={submit}>Save</button>
                { state.success && success }
                { state.error && error }
            </div>
        </div>
    )
}

export default EditProfile