import { useContext } from 'react'
import { ConfigContext } from '../utils/context/configContext'

const Image = ({ img }) => {

    const [config] = useContext(ConfigContext)

    return (
        <div className='image'>
            {
                img ? 
                <img
                    className='fade-in'
                    src={`${config.base_url}/images/${img}`}
                    alt='Celebrity Photo'
                /> :
                <></>
            }
            <div className='image-gradient'></div>
        </div>
    )
}

export default Image