import ResultsTable from './ResultsTable/ResultsTable'

const Info = ({ name, table, blurb }) => {

    return (
        <div className='info'>
            <h1 className='info-name'>{ name }</h1>
            { (blurb && table) && <p className='definition'>{ blurb }</p> }
            {<ResultsTable table={table} />}
        </div>
    )
}

export default Info