import React, { useContext, useState } from "react"
import { useHistory } from "react-router-dom"
import classnames from "classnames"
import Button from "../components/Button"
import ButtonGroup from "../components/ButtonGroup"
import Prompt from "../components/Prompt"
import { ApiClient } from '../etc'
import twitter from '../svg/loginWithTwitter.png'
import useAuth from "../utils/hooks/useAuth"
import { useToken } from "../utils/hooks"
import { ConfigContext } from "../utils/context/configContext"

const Login = () => {

	const [config] = useContext(ConfigContext)
	const { getToken } = useToken()
	const { loadUserWithCreds, toggleAnon } = useAuth()
	let history = useHistory()
	const api = new ApiClient()

	const [state, setState] = useState({
		email: "",
		successful: null,
		createAnAccount: false,
	})

	const handleLogin = async () => {
		if (state.email !== "") {
			const pass = document.getElementById("password-field").value

			const login = await loadUserWithCreds(state.email, pass)

			if (login) {
				history.push("/")
			} else {
				setState({
					...state,
					successful: false,
				})
			}
		} else {
			setState({
				...state,
				successful: false,
			})
		}
	}

	const handleChange = (e) => {
		setState({
			...state,
			email: e.target.value,
		})
	}
	
	const failed = classnames({
		failed: state.successful === false,
	})

	const toggleCreateAnAccount = () => {
		setState({
			...state,
			createAnAccount: !state.createAnAccount,
			successful: null
		})
	}

	const handleCreateAccount = async () => {
		const password = document.getElementById("password-field").value
		const password1 = document.getElementById("verify-password-field").value
		
		if (password !== password1) {

				setState({
					...state,
					successful: false
				})

		} else {

			const token = getToken()

			if(token) {

				const created = await api.mergeUser(token, state.email, password, password1)
	
				if (created) {
					toggleAnon(false)
					history.push("/")
				} else {
					setState({
						...state,
						successful: false,
					})
				}
			}
		}
	}

	const handleSubmit = () => {
		if (state.createAnAccount) {
			handleCreateAccount()
		} else {
			handleLogin()
		}
	}

	const verifyPass = (
		<div>
		<span>Verify Password</span>
		<input type="password" className={failed} id="verify-password-field" />
		</div>
	)

	const handleTwitter = async () => {
		const url = await api.getTwitterURL()
		window.location.href = url
		return null
	}

	const loginWithTwitterEl = (
		<div className="twitter-login">
			<button href={`${config.base_url}/twitter/auth`} onClick={() => handleTwitter()}><img src={twitter} alt="Twitter login"/></button>
		</div>
	)

	return (
		<Prompt>
		<div className="login-form">
			<h1>{state.createAnAccount ? "Create a New Account" : "Login"}</h1>
			{loginWithTwitterEl}
			<div>
				<span>Email</span>
				<input
					type="email"
					onChange={(e) => handleChange(e)}
					className={failed}
				/>
			</div>
			<div>
				<span>Password</span>
				<input type="password" id="password-field" className={failed} />
			</div>
			{state.createAnAccount ? verifyPass : <></>}
		</div>
		<ButtonGroup borderColor="#000">
			<Button textColor="#000" click={() => toggleCreateAnAccount()}>
				{state.createAnAccount ? "Login" : "Create an Account"}
			</Button>
			<Button
				borderColor="#000"
				textColor="#000"
				click={() => handleSubmit()}
			>
			Submit
			</Button>
		</ButtonGroup>
		</Prompt>
	)
}

export default Login
