import classnames from 'classnames'
import './ResultsTable.scss'
import { ApiClient } from '../../etc'
import { useHistory } from 'react-router-dom'

const ResultsTable = ({ table }) => {

    const history = useHistory()

    if (table === null || table === undefined) return <div className="results-table inactive"></div>

    const share = async () => {
        const api = new ApiClient();
        const loggedIn = await api.isLoggedInTwitter();

        if (loggedIn === true) {
            const text = encodeURIComponent("If you aren't playing this game... what are you doing...? #starvsleader starvsleader.com")
            window.open(`https://twitter.com/intent/tweet?text=${text}`, '_blank');
        } else {
            history.push('/login');
        }
    }

    return (
        <div className='results-table active'>
            <div className={classnames('results-table-item', { 'results-table-item-highlight': table.type === 'star' })}>
                <h1 className='results-table-item-percent'>{table.star}%</h1>
                <p className='results-table-item-name'>STAR</p>
            </div>
            <div className={classnames('results-table-item', { 'results-table-item-highlight': table.type === 'noone' })}>
                <h1 className='results-table-item-percent'>{table.noone}%</h1>
                <p className='results-table-item-name'>NO ONE</p>
            </div>
            <div className={classnames('results-table-item', { 'results-table-item-highlight': table.type === 'leader' })}>
                <h1 className='results-table-item-percent'>{table.leader}%</h1>
                <p className='results-table-item-name'>LEADER</p>
            </div>
            <footer className='results-table-footer'>Have an opinion? <span>#starvsleader</span></footer>
        </div>
    )
}

export default ResultsTable