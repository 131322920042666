import React from "react";
import ListItem from "./ListItem";

const ProfileList = ({list, queueUp, profileOrder, remove, setPageState}) => {
	return (
		<div className="leaderboard-list">
			{list.map((p, idx) => {
				return (
					<ListItem
						queueUp={queueUp}
						profileOrder={profileOrder}
						p={p}
						key={idx}
						queueDisabled={false}
						removeProfile={remove}
						setPageState={setPageState}
					/>
				);
			})}
		</div>
	);
};

export default ProfileList;
