import { useContext, useState, useEffect } from 'react'
import Template from '../components/Template'
import Title from '../components/Title'
import Button from '../components/Button'
import LeaderboardTitle from '../components/LeaderboardTitle'
import ButtonGroup from '../components/ButtonGroup'
import classnames from 'classnames'
import { ProfileContext } from '../utils/context/profileContext'
import useProfiles from '../utils/hooks/useProfiles'
import Music from '../components/Music'
import { ApiClient } from '../etc'
import { useHistory } from 'react-router-dom'
import { ConfigContext } from '../utils/context/configContext'
import { useToken } from '../utils/hooks'
import _ from 'lodash'
import { AuthContext } from '../utils/context/authContext'

const Leaderboard = () => {

    const [config] = useContext(ConfigContext)
    const history = useHistory()
    const [tab, setTab] = useState('star')
    const [profileState] = useContext(ProfileContext)
    const [authState] = useContext(AuthContext)
    const { getToken } = useToken()
    const { loadLeaderboard } = useProfiles()

    useEffect(() => {

        const token = getToken()
        loadLeaderboard(token)

    }, [])

    const login = () => history.push('/login')
    const playAgain = () => history.push('/?load=all')

    const share = async () => {
        const api = new ApiClient()
        const loggedIn = await api.isLoggedInTwitter()

        if (loggedIn === false) {
            const text = 'Check out this game! https://starvsleader.com'
            window.open(`https://twitter.com/intent/tweet?text=${text}`, '_blank')
        } else {
            const url = await api.getTwitterURL()
            window.location.href = url
        }
    }

    const openNoOneSocial = () => {
        window.open('https://www.instagram.com/no.one/', '_blank')
    }

    const renderLeaderboard = () => {
        if(profileState.totals) {
            const sorted = _.orderBy(profileState.totals, [tab], ['desc'])
            
            var nooneItem = <></>
            if(tab === 'noone') {
                nooneItem = (
                    <div className='leaderboard-list-item' onClick={openNoOneSocial}>
                        <p className='leaderboard-list-item-center'>
                            <span>@no.one</span>
                            <img src={`${config.base_url}/images/noone.jpeg`} alt='noone' className='profile-image' />
                        </p>
                    </div>
                )
            }

            return (
                <div className='leaderboard-list'>
                    { nooneItem }
                    {
                        sorted.map((p, idx) => {
                            return (
                                <div className='leaderboard-list-item' key={p.profile_id}>
                                    <div className='leaderboard-list-item-left'>
                                        <p className='leaderboard-list-item-left-index'>{ idx + 1 }</p>
                                        <p className='leaderboard-list-item-left-name'>{ p.name }</p>
                                    </div>
                                    <div className='leaderboard-list-item-right'>
                                        <img src={`${config.base_url}/images/${p.image}`} alt='profile' />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            )
        }
    }

    const renderMe = () => {
        if(profileState.myVotes.length > 0) {
            return (
                <div className='leaderboard-list'>
                    {
                        profileState.myVotes.map((p, idx) => {
                            return (
                                <div className='leaderboard-list-item' key={p.profile_id}>
                                    <div className='leaderboard-list-item-left'>
                                        <p className='leaderboard-list-item-left-name'>{ p.name } ({p.vote})</p>
                                    </div>
                                    <div className='leaderboard-list-item-right'>
                                        <img src={`${config.base_url}/images/${p.image}`} alt='profile' />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            )
        }
    }

    const renderTitle = () => {
        if(authState.isAnon) {
            return <LeaderboardTitle text='Log in to see what the world thinks!' />
        } else {
            switch(tab) {
                case 'star':
                    return <LeaderboardTitle text='Who the world thinks is a Star...' />
                case 'leader':
                    return <LeaderboardTitle text='Who the world thinks is a Leader...' />
                case 'noone':
                    return <LeaderboardTitle text='Who the world this is No One...' />
                case 'me':
                    return <LeaderboardTitle text="How you voted..." />
                default:
                    return <></>
            }
        }
    }

    const renderBody = () => {
        if(authState.isAnon) {
            return (
                <ButtonGroup>
                    <Button click={playAgain}>Play</Button>
                    <Button click={login}>Log In</Button>
                </ButtonGroup>
            )
        } else {
            return (
                <div className='leaderboard'>
                    <div className='leaderboard-header'>
                        <div className={classnames('leaderboard-header-category', { 'highlight-text': tab === 'star'})} onClick={() => setTab('star')}>
                            <h1>STARS</h1>
                        </div>
                        <div className={classnames('leaderboard-header-category', { 'highlight-text': tab === 'leader'})} onClick={() => setTab('leader')}>
                            <h1>LEADERS</h1>
                        </div>
                        <div className={classnames('leaderboard-header-category', { 'highlight-text': tab === 'noone'})} onClick={() => setTab('noone')}>
                            <h1>NO ONE</h1>
                        </div>
                        <div className={classnames('leaderboard-header-category', { 'highlight-text': tab === 'me' })} onClick={() => setTab('me')}>
                            <h1>MY VOTES</h1>
                        </div>
                    </div>
                    { render() }
                </div>
            )
        }
    }

    const renderLeft = () => {
        return (
            <>
                { renderTitle() }
                <ButtonGroup className='desktop-left-button-group desktop-height-1-3'>
                    { !authState.isAnon && <Button click={playAgain}>Play</Button> }
                    <Button click={share}>SHARE</Button>
                </ButtonGroup>
            </>
        )
    }

    const render = () => {
        switch(tab) {
            case 'star':
            case 'leader':
            case 'noone':
                return renderLeaderboard()
            case 'me':
                return renderMe()
            default:
                return <></>
        }
    }

    return (
        <Template>
            <Title />
            { renderBody() }
            <Music />
            { renderLeft() }
        </Template>
    )

}

export default Leaderboard